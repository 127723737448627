<template>
  <nav id="nav" class="temporary-nav">
    <router-link to="/">
      <h1 class="nav-logo">Nico Ramsey</h1>
    </router-link>
    <ul>
      <router-link class="nav-links" v-for="(link, index) in linkPath" :key="index" :to="link.path"> {{link.name}}</router-link>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  data () {
    return {
      linkPath: [
        {
          path: '/about',
          name: 'about'
        },
        {
          path: '/leadership',
          name: 'leadership',
        },
        {
          path: '/contact',
          name: 'contact'
        }
      ]
    }
  },
  mounted () {
    this.logoAnimate()
    this.linksAnimate()
  },
  methods: {
    logoAnimate() {
      this.$anime.timeline({loop: false})
        .add({
          targets:'.nav-logo',
          translateX: [-150,0],
          opacity: [0,1],
          duration: 2000,
          easing: "easeOutExpo",
          delay: (el, i) => 1000 * i
        })
    },
    linksAnimate() {
      this.$anime.timeline({loop: false})
        .add({
          targets:'.nav-links',
          translateX: [1150,0],
          opacity: [0,1],
          duration: 2000,
          easing: "easeOutExpo",
          delay: (el, i) => 800 * i
        })
    }
  }
}
</script>

<style lang="scss">
#nav {
  padding: 0 30px;
  height: 80px;
  @include flexRow;
  align-items: center;
  justify-content: space-between;
  font-family:'Copperplate', monospace;
  opacity: .4;
  transition: opacity 550ms ease-out;
  &:hover {
    opacity: 1;
  }
  h1 {
    // color: $yellow-light;
    color: $grey-light;
    font-family:'Copperplate', monospace;
    @include textStyles(24);
    @media only screen and (max-width: $smallBreakpoint) {
        @include textStyles(18);
    }
  }
  &.temporary-nav {
    ul {
      width: 400px;
      @include flexRow;
      justify-content: space-around;
      align-items: center;
      font-family:'Electra LT Std', sans-serif;
      text-transform: capitalize;
    }
  }
  a {
    color: white;
    text-decoration: none;
    font-family: 'Electra LT Std', sans-serif;
    h1 {
      font-family:'Copperplate', monospace;
    }
    &.router-link-exact-active {
      font-weight: bold;
      color: $yellow-light;
    }
  }
}
</style>