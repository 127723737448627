<template>
<div>
  <transition 
      @enter="enterElement" 
      @leave="leaveElement" 
      v-bind:css="false"
      mode="out-in"
      appear
    >
      <div class="page-container" :class="pageName">
        <h1>
          <slot name="title"></slot>
        </h1>
        <main class="page-container-body">
          <div class="page-container-body-content">
            <slot name="body"></slot>
          </div>
          <aside class="page-container-body-aside">
            <slot name="aside"></slot>
          </aside>
        </main>
        <div class="img-contain" data-aos="fade" data-aos-delay="1800" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-once="true">
          <slot name="image"></slot>
        </div>
      </div>
  </transition>
 </div>
</template>

<script>
export default {
  name: 'Page',
  props: {
    pageName: {
      type: String
    }
  },
  data () {
    return {
      currentRoute: null,
      inPage: false
    }
  },
  methods: {
     enterElement(el, done) {
      this.$anime({
        targets: el,
        translateY: [1000, 0],
        opacity: [0, 1],
        easing: 'easeInOutSine',
        complete: done,
      })
    },
    leaveElement(el, done) {
      this.$anime({
        targets: el,
        translateY: [0, -1000],
        opacity: [1, 0],
        easing: 'easeInOutSine',
        complete: done,
      })
    }
  }
}
</script>

<style lang="scss">
  // @import url(//db.onlinewebfonts.com/c/31442d5f0b149a65e0682810dcbeed68?family=Electra+LT+Std);
  .page-container {
    // @include border-type();
    @include box(100%);
    @include flexRow;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: calc(100vh - 80px);
    margin: 0 auto;
    max-width: 1080px;
    margin-top: 80px;
    padding: 0 20px;
    position: relative;
    h1 {
      @include textStyles(42); //need to change to font vw for responsiveness
      line-height: .6;
      color: $yellow-light;
      margin-right: 1.25em;
      font-family: 'Electra LT Std', sans-serif;
    }
    p {
      font-family:'Electra LT Std', serif;
    }
    &-body {
      max-width: 670px;
      margin: 0 auto;
      z-index: 2;
      p {
        margin-bottom: 1em;
        @include textStyles(20, 1.5, -0.025);
      }
    }
    &-body-aside {
      text-align: end;
      font-size: 36px;
      margin-top: 100px;
      p {
        line-height: 1.25;
        letter-spacing: .1em;
        &:nth-child(1) {
          font-weight: bold;
          opacity: .8;
        }
        &:nth-child(2) {
          font-size: 30px;
           @include textStyles(20, 1.25, -0.025);
        }
      }
    }
    .img-contain {
      position: absolute;
      bottom: 20%;
      width: 400px;
      &::before {
        content: '';
        height: 100%;
        width: 400px;
        z-index: 1;
        background: #151d10;
        background: linear-gradient(220deg, #151d10 0%, rgba(0, 212, 255, 0) 44%);
        display: block;
        z-index: 1;
        /* height: 491px; */
        position: absolute;
      }
      @media only screen and (max-width: $smallBreakpoint) {
          position: relative;
          margin-bottom: 100px;
          width: 100%;
          &::before {
            background: none;
            background: transparent;
          }
      }
    }
    @media only screen and (max-width: $smallBreakpoint) {
      flex-direction: column;
      h1 {
        margin-bottom: 1.5em;
      }
    }
  }
</style>