<template>
  <div class="home">
    <h1 class="ml15">
      <span class="word">Nico</span>
      <span class="word">Ramsey</span>
    </h1>
    <div class="titles ml13">
      <h4>Speaker</h4>
      <h4>Activist</h4>
      <h4>Producer</h4>
    </div>
    <div class="arrowdown" @click="showNewPage">
      <div class="arrow-trail"></div>
      <div class="arrow-caps">
        <div class="rotateLeft">
          <div class="arrowdown-caps-one arrow-caps"></div>
        </div>
        <div class="rotateRight">
          <div class="arrowdown-caps-two arrow-caps"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
  mounted () {
    this.animateTitle()
    this.animateSubtitle()
    this.animateArrow()
    },
    methods: {
      showNewPage () {
        this.$router.push('about')
      },
      animateTitle () {
        this.$anime.timeline({loop: false})
          .add({
            targets: '.ml15',
            // scale: [10,1],
            translateX: [-1000,0],
            translateZ: 0,
            opacity: [0,1],
            easing: "easeOutCirc",
            duration: 1250,
            delay: (el, i) => 1250 * i
          })
      },
      animateSubtitle () {
        let textWrapper = document.querySelectorAll('.ml13 h4');
        textWrapper.forEach(text => {
          text.innerHTML = text.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        })
        this.$anime.timeline({loop: false})
          .add({
            targets: '.ml13 .letter',
            translateY: [100,0],
            translateZ: 0,
            opacity: [0,1],
            easing: "easeOutExpo",
            duration: 2000,
            delay: (el, i) => 2000 + 30 * i
          })
      },
      animateArrow () {
        this.$anime.timeline({loop: false})
          .add({
            targets:'.arrowdown',
            translateY: [-50,0],
            opacity: [0,1],
            duration: 2000,
            easing: "easeOutExpo",
            delay: (el, i) => 3000 + 30 * i
          })
      }
    }
}
</script>
<style lang="scss">
.home {
  @include box(100%);
  @include flexColumn;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .ml15 .word {
    display: inline-block;
    line-height: 1em;
    margin-right: 10px;
    color: $yellow;
  }
  .ml13 {
    letter-spacing: 0.5em;
  }
  .ml13 .letter {
    display: inline-block;
    line-height: 1em;
  }
  h1 {
    font-family:'Copperplate', monospace;
    @include textStyles(80);
    @media only screen and (max-width: $smallBreakpoint) {
        @include textStyles(52);
    }
  }
  .titles {
    @include flexRow;
    // color: black;
    // color: whitesmoke;
    h4 {
      font-family:'Electra LT Std', serif;
      @include textStyles(32);
      @media only screen and (max-width: $smallBreakpoint) {
        @include textStyles(22);
    }
    }
    h4:not(:last-child) {
      margin-right: 20px;
    }
  }
  .arrowdown {
    position: absolute;
    bottom: 100px;
    right: 0;
    width: 44px;
    height: 40px;
    left: 50%;
    transform: translateX(-50%) scaleX(.5) scaleY(.5);
    cursor: pointer;
    z-index: 99999;
    display: block;
    // animation: pulse 2s ease-in infinite;
  }
  .arrowdown .arrow-trail {
    height: 40px;
    width: 1px;
    text-align: center;
    background-color: white;
    // background-color: #aaa;
    position: absolute;
    transform-origin: 50% 0;
    margin-left: 22px;
    top: 0;
    transform: matrix(1, 0, 0, 1, 0, 0);
    // animation: pulse 2s ease-in infinite;
  }
  .arrowdown .rotateLeft {
    position: absolute;
    bottom: 0;
    transform: rotate(45deg);
    margin-left: 22px;
  }
  .arrowdown .arrowdown-caps-one, .arrowdown .arrowdown-caps-two {
    position: absolute;
    bottom: 0;
    width: 1px;
    height: 16px;
    transform-origin: 50% 100%;
    background-color: white;
    animation: pulse 2s ease-in infinite;
  }
  .arrowdown .arrowdown-caps-one {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .arrowdown .arrowdown-caps-two {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .arrowdown .rotateRight {
    position: absolute;
    bottom: 0;
    transform: rotate(-45deg);
    margin-left: 22px;
  }
}
// @keyframes pulse {
//   0% {
//     -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
//     box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
//   }
//   70% {
//       -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
//       box-shadow: 0 0 0 10px rgba(204,169,44, 0);
//   }
//   100% {
//       -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
//       box-shadow: 0 0 0 0 rgba(204,169,44, 0);
//   }
// }
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    // -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    // box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    // -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    //   box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    // -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    //   box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
  
}
</style>
