<template>
  <div id="app">
    <navigation v-if="!isHomePage" />
    <router-view/>
    <footer v-if="isHomePage"></footer>
  </div>
</template>
<script>
import Navigation from '@/components/Navigation'
export default {
  components: { Navigation },
  data () {
    return {
      isHomePage: true
    }
  },
  watch: {
    '$route': function(data) {
      console.log('watch data:', data)
      console.log('data.name === "Home":', data.name === "Home")
      this.isHomePage = data.name === "Home"
    }
  }
}
</script>
<style lang="scss">
@font-face {
    font-family: 'Electra LT Std';
    src: url('assets/fonts/ElectraLTstd/ElectraLTStd-Regular.woff2') format('woff2'),
        url('assets/fonts/ElectraLTstd/electraltstd-bold.otf') format('otf'),
        url('assets/fonts/ElectraLTstd/ElectraLTStd-Regular.otf') format('otf'),
        url('assets/fonts/ElectraLTstd/ElectraLTStd-Bold.woff2') format('woff2'),
        url('assets/fonts/ElectraLTstd/ElectraLTStd-Bold.woff') format('woff'),
        url('assets/fonts/ElectraLTstd/ElectraLTStd-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Copperplate';
  src: url('assets/fonts/Copperplate/Copperplate.woff2') format('woff2'),
      url('assets/fonts/Copperplate/Copperplate.woff') format('woff'),
      url('assets/fonts/Copperplate/CopperplateRegular.ttf') format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
#app {
  font-family: Electra LT Std, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  color: white;
  min-height: 100vh;
  height: 100%;
  background-color: $primary-bg;
}
footer {
  height: 80px;
}
</style>
